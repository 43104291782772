import ProductCard from "../Product Card/ProductsCard";
import "./Products.scss";
import {
  mensClothingProducts,
  kidsClothingProducts,
  womensClothingProducts,
  sareeProducts,
  mixProduct,
} from "../../Datas/Product";

const Products = () => {
  return (
    <div className="all-product-container section">
      <div className="new-products">
        <h1 className="heading-product">Churidar Collection</h1>
        <p>Graceful Churidars for a Timeless Look</p>
        <div className="product-container">
          {womensClothingProducts.map((item) => {
            return <ProductCard item={item} key={item.id} />;
          })}
        </div>
      </div>
      <div className="new-products">
        <h1 className="heading-product">Sarees for Every Occasion</h1>
        <p>Elegant Sarees for Traditional and Modern Women</p>
        <div className="product-container">
          {sareeProducts.map((item) => {
            return <ProductCard item={item} key={item.id} />;
          })}
        </div>
      </div>
      <div className="new-products">
        <h1 className="heading-product">Lehengas for Festive Celebrations</h1>
        <p>Exquisite Lehengas to Make You Stand Out</p>
        <div className="product-container">
          {mixProduct.map((item) => {
            return <ProductCard item={item} key={item.id} />;
          })}
        </div>
      </div>
      <div className="new-products">
        <h1 className="heading-product">Men’s Wear – Casual and Formal</h1>
        <p>Stylish Shirts for Every Occasion</p>
        <p></p>
        <div className="product-container">
          {mensClothingProducts.map((item) => {
            return <ProductCard item={item} key={item.id} />;
          })}
        </div>
      </div>
      <div className="new-products">
        <h1 className="heading-product">Explore Kids' Wear</h1>
        <p>Casual T-Shirts, Jeans, Hoodies, Jackets, for  Kids</p>
        <div className="product-container">
          {kidsClothingProducts.map((item) => {
            return <ProductCard item={item} key={item.id} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Products;
