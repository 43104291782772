import './Testimoni.scss';
import CountUp from "react-countup";
import { testimonial } from '../../Datas/Testimonial';

function Testimoni() {
  return (
    <div className="counter-components" id='service'>
      <div className="counter-container">
        <div className="items">
          <h3><CountUp duration={10} className="counter" end={testimonial.Experience} />+</h3>
          <p>Year of Experience</p>
        </div>
        <div className="items">
          <h3><CountUp duration={10} className="counter" end={testimonial.Employess} />+</h3>
          <p>Number of Employess</p>
        </div>
        <div className="items">
          <h3><CountUp duration={10} className="counter" end={testimonial.Customers} />K+</h3>
          <p>Number of Customers</p>
        </div>
        <div className="items">
          <h3><CountUp duration={10} className="counter" end={testimonial.HappyCustomers} />K+</h3>
          <p>Number of Happy Customers</p>
        </div>
      </div>
    </div>
  )
}

export default Testimoni