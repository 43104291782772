import './HomeVideo.scss';
import video1 from '../../Assates/video/sudhirbastralaya.mp4';
import { IoClose } from "react-icons/io5";

function HomeVideo(props) {
    return (
        <div className='video-container'>
            <div onClick={() => props.setVideoIs(false)} className='close-btn'>
                <IoClose className='icon' />
            </div>
            <video src={video1} autoPlay className='video-box' loop muted></video>
        </div>
    )
}

export default HomeVideo