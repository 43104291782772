import HomePage from "../Pages/HomePage";
import AboutPage from "../Pages/AboutPage";
import TestimoniPage from "../Pages/TestimoniPage";
import ReviewPage from "../Pages/ReviewPage";
import ContactPage from "../Pages/ContactPage";
import ProductBanner from "../Component/Product Banner/ProductBanner";
import Products from "../Component/Products/Products";
import {sixthBanner, fifthBanner, forthdBanner, thirdBanner, secBanner, fastBanner} from "../Datas/BannerData"

function LandingPage() {
  return (
    <div>
      <HomePage />
      <ProductBanner data={fastBanner} />
      <Products />
      <ProductBanner data={secBanner}/>
      <TestimoniPage />
      <ProductBanner data={thirdBanner}/>
      <AboutPage />
      <ProductBanner data={forthdBanner}/>
      <ProductBanner data={fifthBanner}/>
      <ReviewPage />
      <ProductBanner data={sixthBanner}/>
      <ContactPage />
    </div>
  );
}

export default LandingPage;
