import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FaQuoteRight } from "react-icons/fa6";
import {ReviewItem} from '../../Datas/Review';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1180, min: 768 },
        items: 2,
        slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 850, min: 425 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

function Slider() {
    return (
        <div className="parent">
            <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                dotListClass="custom-dot-list-style"
            >
                {ReviewItem?.reviews?.map((item, index) => (
                    <div className="slider" key={index}>
                        <div className="image-container">
                            <img src={item.Image} alt="sudhir bastralaya reviews" className='image' />
                        </div>

                        <div className="head-box">
                            <p className="dr-title">{item.name}</p>
                            <p className="dr-category">{item.dec}</p>
                        </div>

                        <p className="paragrapg">{item.review}</p>
                        <FaQuoteRight className="icon"/>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};
export default Slider;