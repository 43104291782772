import React from "react";
import "./ProductCard.scss";

const ProductCard = ({ item }) => {
  const { name, img } = item;

  return (
    <div className="product-card" key="">
      <img src={img} alt="" />
      <div className="details">
        <h3 className="product-heading">{name}</h3>
        {/* <p className="product-sub">{brand}</p> */}
      </div>
    </div>
  );
};

export default ProductCard;
