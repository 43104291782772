import logo from '../Assates/Logo/sb 72px.png';


export const AppItem = [
    {
        name : "Home",
        link : "#home",
    },
    {
        name : "About",
        link : "#about",
    },
    {
        name : "Product",
        link : "#product",
    },
    {
        name : "Review",
        link : "#review",
    },
    {
        name : "Contact",
        link : "#contact",
    }
]

export const brandItem = {
    logo : logo,
    brand : 'Sudhir Bastralaya'
}