import './Review.scss';
import Slider from '../../Component/Slider/Slider';
import {ReviewItem} from '../../Datas/Review';
function Review() {
  return (
    <div className='review-components' id='review'>
      <div className='review-container'>
        <div className="review-heading-container">
          <h1 className="heading-title">{ReviewItem.title}</h1>
          <p className="heading-tag">{ReviewItem.headline1}</p>
        </div>

        <div className="review-buttom-container">
          <Slider />
        </div>

      </div>
    </div>
  )
}

export default Review;