export const ReviewItem = {
  title: "Testimonial",
  headline1: "Customer Reviews",
  reviews: [
    {
      name: "Newton",
      dec: "Happy Coustomer",
      Image: "https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?auto=compress&cs=tinysrgb&w=600",
      review:"Being a fashion enthusiast i look for trendy and stylish product with good material used,i am amazed by the product provided by sudhir bastralaya karimpur,i did not believe i can find such product at affordable price, until i visited their showroom",
    },
    {
      name: "Prosenjit",
      dec: "Happy Coustomer",
      Image: "https://images.pexels.com/photos/23696195/pexels-photo-23696195/free-photo-of-man-posing-in-traditional-clothes.jpeg?auto=compress&cs=tinysrgb&w=600",
      review:"I can't even express how excited I was when i saw Such well made Shirt at affordable price, They have trendy design, Unbeliveable Trendy collection available in karimpur",
    },
    {
      name: "Prabhat",
      dec: "Happy Coustomer",
      Image: "https://images.pexels.com/photos/2590287/pexels-photo-2590287.jpeg?auto=compress&cs=tinysrgb&w=600",
      review:"Being a fashion enthusiast i look for trendy and stylish product with good material used,i am amazed by the product provided by sudhir bastralaya karimpur,i did not believe i can find such product at affordable price, until i visited their showroom",
    },
    {
      name: "Joyee",
      dec: "Happy Coustomer",
      Image: "https://images.pexels.com/photos/1580271/pexels-photo-1580271.jpeg?auto=compress&cs=tinysrgb&w=600",
      review:"I can't even express how excited I was when i saw Such well made Shirt at affordable price, They have trendy design, Unbeliveable Trendy collection available in karimpur",
    },
    {
      name: "Sounak",
      dec: "Happy Coustomer",
      Image: "https://images.pexels.com/photos/3725497/pexels-photo-3725497.jpeg?auto=compress&cs=tinysrgb&w=600",
      review:"Being a fashion enthusiast i look for trendy and stylish product with good material used,i am amazed by the product provided by sudhir bastralaya karimpur,i did not believe i can find such product at affordable price, until i visited their showroom",
    },
    {
      name: "Sanjukta",
      dec: "Happy Coustomer",
      Image: "https://images.pexels.com/photos/725458/pexels-photo-725458.jpeg?auto=compress&cs=tinysrgb&w=600",
      review:"I can't even express how excited I was when i saw Such well made Shirt at affordable price, They have trendy design, Unbeliveable Trendy collection available in karimpur",
    },

    {
      name: "Rajhanna",
      dec: "Happy Coustomer",
      Image: "https://images.pexels.com/photos/1580271/pexels-photo-1580271.jpeg?auto=compress&cs=tinysrgb&w=600",
      review:"I can't even express how excited I was when i saw Such well made Shirt at affordable price, They have trendy design, Unbeliveable Trendy collection available in karimpur",
    },
    {
      name: "Darshana",
      dec: "Happy Coustomer",
      Image: "https://images.pexels.com/photos/1684724/pexels-photo-1684724.jpeg?auto=compress&cs=tinysrgb&w=600",
      review:"Being a fashion enthusiast i look for trendy and stylish product with good material used,i am amazed by the product provided by sudhir bastralaya karimpur,i did not believe i can find such product at affordable price, until i visited their showroom",
    },
    {
      name: "Sanjukta",
      dec: "Happy Coustomer",
      Image: "https://images.pexels.com/photos/725458/pexels-photo-725458.jpeg?auto=compress&cs=tinysrgb&w=600",
      review:"I can't even express how excited I was when i saw Such well made Shirt at affordable price, They have trendy design, Unbeliveable Trendy collection available in karimpur",
    },
    {
      name: "Sunanda",
      dec: "Happy Coustomer",
      Image: "https://images.pexels.com/photos/1139450/pexels-photo-1139450.jpeg?auto=compress&cs=tinysrgb&w=600",
      review:"Being a fashion enthusiast i look for trendy and stylish product with good material used,i am amazed by the product provided by sudhir bastralaya karimpur,i did not believe i can find such product at affordable price, until i visited their showroom",
    },
    {
      name: "Arvind",
      dec: "Happy Coustomer",
      Image: "https://images.pexels.com/photos/1507699/pexels-photo-1507699.jpeg?auto=compress&cs=tinysrgb&w=600",
      review:"I can't even express how excited I was when i saw Such well made Shirt at affordable price, They have trendy design, Unbeliveable Trendy collection available in karimpur",
    },
  ],
};
