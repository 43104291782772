import "./ProductBanner.scss";

const ProductBanner = ( {data}) => {
  const { firstContent , secondContent , thirdContent} = data
  return (
    <div className="banner" id="product">
      <div className="main-banner">
        <div className="image-box">
          <img src={firstContent?.img} alt="" />
          <div className="banner-heading">
            <h3>{firstContent?.upperHeading}</h3>
            <h1> {firstContent?.headimg}</h1>
          </div>
        </div>
        <div className="img-container">
          <div className="image-box-1">
            <div className="banner-sub-heading">
              <h3>{secondContent?.upperHeading}</h3>
              <h1> {secondContent?.headimg}</h1>
            </div>
            <img src={secondContent?.img} alt="" />
          </div>
          <div className="image-box-2">
            <div className="banner-sub-heading">
              <h3> {thirdContent?.upperHeading}</h3>
              <h1>{thirdContent?.headimg}</h1>
            </div>
            <img src={thirdContent?.img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductBanner;
