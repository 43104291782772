import "./Contact.scss";
import { MdOutlineEmail, MdCall } from "react-icons/md";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./Contact.scss";
import { ThreeDots } from "react-loader-spinner";
import { ContactItem } from "../../Datas/Contact";
import Swal from "sweetalert2";
import { newContacts } from "../../Firebase/firebase";

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Name is a required field")
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must be at most 50 characters"),
  email: yup
    .string()
    .required("Email is a required field")
    .email("Must be a valid email"),
  number: yup
    .string()
    .required("Number is a required field")
    .matches(/^[0-9]+$/, "Number must be only digits")
    .min(10, "Number must be at least 10 digits")
    .max(12, "Number must be at most 12 digits"),
  message: yup
    .string()
    .required("Message is a required field")
    .min(10, "Message must be at least 10 characters")
    .max(500, "Message must be at most 500 characters"),
  newsletter: yup
    .boolean()
    .notRequired()
    .oneOf([true, false], "Newsletter must be true or false"),
}).required();


function Contact() {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });
  const onSubmit = async (data) => {
    setLoading(true);
    const result = await newContacts(data);
    if (result.success) {
      Swal.fire({
        title: "Thank You!",
        text: "Message Send Successfull",
        icon: "success",
      });
      reset();
    } else {
      Swal.fire({
        title: "Opps! Sorry",
        text: "Please Try Again",
        icon: "error",
      });
    }
    setLoading(false);
  };

  return (
    <div className="contact-comonent" id="contact">
      <div className="contact-container">
        <div className="left">
          <form
            className="form-container"
            onSubmit={handleSubmit(onSubmit)}
            id="form"
          >
            <p className="form-head">Let's Connect</p>

            <div className="group-input-box">
              <div className="input-field-1">
                <input
                  type="text"
                  placeholder=" Your Name"
                  name="name"
                  {...register("name")}
                />
                <p>{errors.name?.message}</p>
              </div>
              <div className="input-field-1">
                <input
                  type="email"
                  placeholder=" Your Email"
                  name="email"
                  {...register("email")}
                />
                <p>{errors.email?.message}</p>
              </div>
            </div>

            <div className="input-field">
              <input
                type="number"
                placeholder=" Your Number"
                name="number"
                {...register("number")}
              />
              <p>{errors.number?.message}</p>
            </div>

            <div className="textarea-box">
              <textarea
                name="message"
                placeholder=" How can we help"
                {...register("message")}
              ></textarea>
              <p>{errors.message?.message}</p>
            </div>

            <div className="form-footer">
              <div className="input-field-3">
                <label>
                  <input
                    type="checkbox"
                    {...register("newsletter")}
                  />
                  I want to recieve newsletters
                </label>
              </div>

              {loading ? (
                <button className="button-loder">
                  Sending
                  <ThreeDots
                    radius="3"
                    color="#fff"
                    visible={true}
                    height="20"
                    width="50"
                  />
                </button>
              ) : (
                <button className="button">Send Message</button>
              )}
            </div>
          </form>
        </div>

        <div className="right">
          <div className="top-container">
            <h3 className="title">{ContactItem.title}</h3>
            <h3 className="tag">{ContactItem.headline1}</h3>
            <p className="paragraph">{ContactItem.dascription}</p>
          </div>
          <div className="box-container">
            <div className="box-item">
              <div className="icon-box">
                <MdCall className="icons" />
              </div>

              <div className="item">
                <h6>Call Us</h6>
                <p className="text-ref">{ContactItem.phone}</p>
              </div>
            </div>
            <div className="box-item">
              <div className="icon-box">
                <MdOutlineEmail className="icons" />
              </div>
              <div className="item">
                <h6>Email</h6>
                <p className="text-ref">{ContactItem.email}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
