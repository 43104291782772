import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, set, push } from "firebase/database";
import { v4 as uuidv4 } from "uuid";

const firebaseConfig = {
  apiKey: "AIzaSyBPMyA7_1ODz9qDjcthqUvGcuKbuQnu7x8",
  authDomain: "aspireworldtechsolutions-4a61b.firebaseapp.com",
  databaseURL:
    "https://aspireworldtechsolutions-4a61b-default-rtdb.firebaseio.com",
  projectId: "aspireworldtechsolutions-4a61b",
  storageBucket: "aspireworldtechsolutions-4a61b.appspot.com",
  messagingSenderId: "916982780056",
  appId: "1:916982780056:web:4380c45a5236fb9d0680ed",
  measurementId: "G-P4BC862PQ4",
};
const app = initializeApp(firebaseConfig);

function generateId() {
  return uuidv4();
}
function currentTimestamp() {
  return Date.now();
}

export async function newContacts(data) {
  const database = getDatabase(app);
  const contactsRef = ref(database, "sudhir-bastralaya/contacts");
  const newslettersRef = ref(database, "sudhir-bastralaya/newsletters");
  try {
    const snapshot = await get(contactsRef);
    let contactsArray = snapshot.exists() ? snapshot.val() : [];
    const newContact = {
      id: generateId(),
      name: data.name,
      email: data.email,
      number: data.number,
      message: data.message,
      newsletter: data.newsletter,
      status: true,
      timestamp: currentTimestamp(),
    };
    contactsArray.push(newContact);
    await set(contactsRef, contactsArray);
    if (data.newsletter) {
      await addNewsletterEmail(data.email);
    }
    return { success: true, message: "Message sent successfully" };
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function addNewsletterEmail(email) {
  const database = getDatabase(app);
  const newslettersRef = ref(database, "sudhir-bastralaya/newsletters");
  try {
    const newslettersSnapshot = await get(newslettersRef);
    let newslettersArray = newslettersSnapshot.exists()
      ? newslettersSnapshot.val()
      : [];
    const emailExists = newslettersArray.some(
      (newsletter) => newsletter.email === email
    );
    if (emailExists) {
      return {success: false, message: "Email already exists"};
    }
    const newNewsletterEntry = {
      id: generateId(),
      email: email,
      timestamp: currentTimestamp(),
    };
    newslettersArray.push(newNewsletterEntry);
    await set(newslettersRef, newslettersArray);
    return { success: true, message: "Subscribe successfully" };
  } catch (error) {
    return { success: false, message: error.message };
  }
}
