import './About.scss';
import aboutImg from '../../Assates/PageImage/about.png';
import { AboutItem } from '../../Datas/About';

function About() {
  return (
    <div className="about-component" id="about">
      <div className="about-container">
        <div className="left-box">
          <h3 className="title">{AboutItem.title}</h3>
          <h3 className="tag">{AboutItem.headline1}</h3>
          <h3 className="tag">{AboutItem.headline2}</h3>
          <p className="paragraph">{AboutItem.dascription}</p>
        </div>
        <div className="right-box">
          <img src={aboutImg} alt="Sudhir Bastralaya" className='aboutImg'/>
        </div>
      </div>
    </div>
  )
}

export default About