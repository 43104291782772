import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppBar from "./Component/AppBar/AppBar";
import Footer from "./Component/Footer/Footer";
import LandingPage from "./LandingPage/LandingPage";
import PageNotFound from "./PageNotFound/PageNotFound";
function App() {
  return (
    <div className="App">
      <Router>
        <AppBar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
