import './AppBar.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {AppItem} from '../../Datas/Navbar';
import { useState } from 'react';
import {brandItem} from '../../Datas/Navbar';

function AppBar() {
    const [target, setTarget] = useState("Home");
    const expand = "lg";
    return (
        <Navbar key={expand} expand={expand} className={`"bg-body-tertiary mb-3 appbar`}>
            <Container fluid className="box-container">
                <Navbar.Brand href="/" className='brand'>
                    <img src={brandItem.logo} alt="logo" className='logo' />
                    {brandItem.brand}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className='menu-btn'/>
                <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement="end">
                    {/* <Offcanvas.Header closeButton className='mobile-nav' >
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className='nav-head-box'>
                            <img src={brandItem.logo} alt="logo" className='logo' />
                            {brandItem.brand}
                        </Offcanvas.Title>
                    </Offcanvas.Header> */}
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3 items-box">
                            {
                                AppItem.map((item, index) => (
                                    <Nav.Link href={item.link} className={`href-item ${target === item.name ? "focus-item" : null }`} key={index} onClick={()=>setTarget(item.name)}>{item.name}</Nav.Link>
                                ))
                            }
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}

export default AppBar