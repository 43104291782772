import img from "../Assates/banner/1.jpg";
import img3 from "../Assates/banner/4.jpg";
import img4 from "../Assates/banner/5.jpg";
import cat4 from '../Assates/catlog/cat4.webp';
import cat5 from '../Assates/catlog/cat5.webp';
import cat6 from '../Assates/catlog/cat6.webp';
import cat7 from '../Assates/catlog/cat7.webp';
import cat9 from '../Assates/catlog/cat9.webp';
import cat10 from '../Assates/catlog/cat10.webp';
import cat11 from '../Assates/catlog/cat11.webp';
import cat12 from '../Assates/catlog/cat12.webp';
import cat13 from '../Assates/catlog/cat13.webp';
import cat14 from '../Assates/catlog/cat14.webp';
import cat15 from '../Assates/catlog/cat15.webp';
import cat16 from '../Assates/catlog/cat16.webp';

export const fastBanner = {
  firstContent: {
    upperHeading: "Elegant Ethnic Wear",
    headimg: "For Festive Seasons",
    img: img,
  },
  secondContent: {
    upperHeading: "Comfortable Cotton",
    headimg: "For All Ages",
    pra: "Cotton, everyday wear fashion for all ages, Karimpur clothing",
    img: cat7,
  },
  thirdContent: {
    upperHeading: "Comfortable jeans",
    headimg: "For All Ages",
    pra: "Jeans, everyday wear fashion for all ages, Karimpur clothing",
    img: cat4,
  },
};
export const secBanner = {
  firstContent: {
    upperHeading: "Stylish and Comfortable ",
    headimg: "Ethnic Wear for Woman",
    img: cat9,
  },
  secondContent: {
    upperHeading: "Trendy Party Wear",
    headimg: "For Women",
    pra: " Explore Shudhir Bastralaya's Coveted Collection!",
    img: cat15,
  },
  thirdContent: {
    upperHeading: "Comfortable Casual",
    headimg: "Shirts for Woman",
    pra: " Shudhir Bastralaya's Must-Have Selections Await!",
    img: cat12,
  },
};
export const thirdBanner = {
  firstContent: {
    upperHeading: "Festive Shart",
    headimg: "Collection for Men",
    img: cat13,
  },
  secondContent: {
    upperHeading: "Festive T-Shart",
    headimg: "Collection for Men",
    pra: " Explore Shudhir Bastralaya's Coveted Collection!",
    img: cat11,
  },
};
export const forthdBanner = {
  firstContent: {
    upperHeading: "Easy-to-Wear",
    headimg: "Kurtas for Woman",
    img: cat16,
  },
  secondContent: {
    upperHeading: "Special Occasions",
    headimg: "Shart for Woman", 
    pra: " Explore Shudhir Bastralaya's Coveted Collection!",
    img: cat10,
  },
};
export const fifthBanner = {
  firstContent: {
    upperHeading: "Warm Woolen",
    headimg: "Shawls for Women",
    img: cat14,
  },
  secondContent: {
    upperHeading: "Trendy Party Wear",
    headimg: "Enhance Every Outfit",
    pra: " Explore Shudhir Bastralaya's Coveted Collection!",
    img: cat5,
  },
};
export const sixthBanner = {
  firstContent: {
    upperHeading: "Soft Cotton Nightwear",
    headimg: "For Senior Citizens",
    img: cat6,
  },
  secondContent: {
    upperHeading: "Stylish and Comfortable",
    headimg: "Ethnic Wear for Kids",
    pra: " Explore Shudhir Bastralaya's Coveted Collection!",
    img: img4,
  },
  thirdContent: {
    upperHeading: "Casual T-Shart Collection",
    headimg: "For Everyday Comfort",
    pra: " Shudhir Bastralaya's Must-Have Selections Await!",
    img: img3,
  },
};